<!--
  Emits event('alterData') with a payload of payload.data and payload.percentage.
-->

<template>
  <div>
    <div class="block text-sm font-medium text-gray-700">{{ title }}</div>
    <div class="flex md:mx-0 rounded py-1 items-center">
      <div class="relative">
        <input
          type="number"
          min="0"
          class="w-12 text-center no-arrows border mr-1 py-1 rounded-lg"
          v-model="percentage"
        />
        <span class="absolute left-2 top-0 my-1">%</span>
      </div>
      <DualButton @execute="handleButtonMethods($event)" :options="options" position="right" />
    </div>
  </div>
</template>

<script>
import DualButton from '@/components/shared/DualButton';
export default {
  data: function () {
    return {
      percentage: null,
      options: [
        { name: 'Increase', text: '' },
        { name: 'Decrease', text: '' },
      ],
    };
  },
  props: {
    title: String,
    name: String,
  },
  computed: {},
  watch: {},
  methods: {
    handleButtonMethods(option) {
      switch (option.name) {
        case 'Increase':
          this.increment();
          break;
        default:
          this.decrement();
          break;
      }
    },
    increment() {
      if (this.percentage) {
        if (
          confirm(
            `Are you sure you would like to increase the price of every ${this.name} product by ${this.percentage}%?`
          )
        ) {
          const percentage = this.percentage / 100 + 1;
          this.alterData(percentage);
        }
      }
    },
    decrement() {
      if (this.percentage) {
        if (
          confirm(
            `Are you sure you would like to decrease the price of every ${this.name} product by ${this.percentage}%?`
          )
        ) {
          const percentage = 1 - this.percentage / 100;
          this.alterData(percentage);
        }
      }
    },
    alterData(percentage) {
      this.$emit('alterData', {
        data: this.name,
        percentage,
      });
    },
  },
  mounted() {},
  components: {
    DualButton,
  },
};
</script>
<style scoped></style>
