var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex text-center items-center space-x-4 z-10"},[_c('DualButton',{attrs:{"options":_vm.page === 'Products'
        ? _vm.productsOptions
        : _vm.page === 'Users'
        ? _vm.userOptions
        : _vm.page === 'Awaiting Products'
        ? _vm.waitingOptions
        : _vm.page === 'Update Invoice'
        ? _vm.updateOptions
        : _vm.page === 'Vendors'
        ? _vm.vendorOptions
        : _vm.page === 'Product Editor'
        ? _vm.productOption
        : _vm.fulfilledOptions,"verticalPosition":_vm.verticalPosition},on:{"execute":function($event){return _vm.handleButtonMethods($event)}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }