<template>
  <ModalPopup v-if="showModal" @close="showModal = false" title="Price Change Email">
    <div class="h-1/2-screen overflow-y-scroll relative">
      <div class="absolute right-0" v-if="pageName === 'Vendors'">
        <DualButton
          class="w-48"
          :options="options"
          position="right"
          @execute="handleButtonMethods($event)"
        />
      </div>
      <div v-else>
        <button class="absolute right-0 btn-action rounded" @click="send">Send</button>
      </div>
      <div v-for="user in users" :key="user._id">
        <label :for="user._id">
          <input type="checkbox" :id="user._id" @change="alterUsers(user)" />
          {{ user.email }}
        </label>
      </div>
    </div>
  </ModalPopup>
</template>

<script>
import ModalPopup from '@/components/shared/DesktopModal.vue';
import DualButton from '@/components/shared/DualButton';
export default {
  data: function () {
    return {
      selectedUsers: new Map(),
      options: [
        { name: 'Increase', text: 'Send email to inform customers of an increase in price' },
        { name: 'Decrease', text: 'Send email to inform customers of a decrease in price' },
      ],
    };
  },
  props: {},
  computed: {
    pageName() {
      return this.$route.name;
    },
    showModal: {
      get() {
        return this.$store.getters['tags/getPriceChangeEmailer'];
      },
      set(val) {
        this.$store.commit('tags/setPriceChangeEmailer', val);
      },
    },
    users: {
      get() {
        return this.$store.getters['users/getPrimaryUsers'];
      },
    },
    vendor: {
      get() {
        return this.$store.getters['tags/getVendor'];
      },
    },
    percentage: {
      get() {
        return this.$store.getters['tags/getPercentage'];
      },
    },
  },
  watch: {
    showModal() {
      this.selectedUsers = new Map();
    },
  },
  methods: {
    handleButtonMethods(option) {
      switch (option.name) {
        case 'Increase':
          this.sendPriceIncreaseEmail();
          break;

        default:
          this.sendPriceDecreaseEmail();
          break;
      }
    },
    send() {
      if (this.selectedUsers.size > 0) {
        this.$emit('overWriteAndSendEmail', [...this.selectedUsers.values()]);
      }
    },
    alterUsers(user) {
      if (this.selectedUsers.has(user._id)) {
        this.selectedUsers.delete(user._id);
      } else {
        this.selectedUsers.set(user._id, { email: user.email, name: user.name });
      }
    },
    sendPriceIncreaseEmail() {
      if (this.selectedUsers.size > 0) {
        this.$store.dispatch('tags/sendPriceIncreaseEmail', {
          users: [...this.selectedUsers.values()],
          vendor: this.vendor,
          percentage: this.percentage,
        });
      }
    },
    sendPriceDecreaseEmail() {
      if (this.selectedUsers.size > 0) {
        this.$store.dispatch('tags/sendPriceDecreaseEmail', {
          users: [...this.selectedUsers.values()],
          vendor: this.vendor,
          percentage: this.percentage,
        });
      }
    },
  },
  mounted() {
    this.$store.dispatch('users/getPrimaryUsers', '');
  },
  components: {
    ModalPopup,
    DualButton,
  },
};
</script>
<style scoped></style>
