<template>
  <div class="flex text-center items-center space-x-4 z-10">
    <DualButton
      :options="
        page === 'Products'
          ? productsOptions
          : page === 'Users'
          ? userOptions
          : page === 'Awaiting Products'
          ? waitingOptions
          : page === 'Update Invoice'
          ? updateOptions
          : page === 'Vendors'
          ? vendorOptions
          : page === 'Product Editor'
          ? productOption
          : fulfilledOptions
      "
      :verticalPosition="verticalPosition"
      @execute="handleButtonMethods($event)"
    />
  </div>
</template>

<script>
import axios from 'axios';
import DualButton from '@/components/shared/DualButton';

export default {
  props: {
    items: { type: Array },
    page: { type: String, default: 'Awaiting Products' },
    payload: { type: Object },
    verticalPosition: { type: String },
  },
  data: function () {
    const connectQuickBooksOption = {
      name: 'Connect to Quickbooks',
      text: 'Before you can sync products, you have to connect to quickbooks.',
      buttonMethod: 'sign_in',
      isConnected: this.isConnected,
    };
    return {
      finished: false,
      force: false,
      isConnected: false,
      connectQuickBooksOption,
      productsOptions: [
        connectQuickBooksOption,
        {
          name: 'Products to Quickbooks',
          text:
            'If items are selected, this will overwrite the cost of products in quickbooks. If no items are selected, this will create items in quickbooks which do not have an associated quickbooks id.',
          buttonMethod: 'sync',
        },
        {
          name: 'Products from Quickbooks',
          text:
            'This will pull all of the products from quickbooks and overwrite inventory and cost.',
          buttonMethod: 'sync_two',
        },
      ],
      productOption: [
        connectQuickBooksOption,
        {
          name: 'Send Product to Quickbooks',
          buttonMethod: 'sync',
        },
      ],
      waitingOptions: [
        connectQuickBooksOption,
        {
          name: 'Create Purchase Order',
          text: 'Sync order to quickbooks to create a purchase order',
          buttonMethod: 'sync',
        },
      ],
      fulfilledOptions: [
        connectQuickBooksOption,
        {
          name: 'Create Invoice',
          text: 'Sync order to quickbooks to create an invoice',
          buttonMethod: 'sync',
        },
      ],
      userOptions: [
        connectQuickBooksOption,
        {
          name: 'Users to quickbooks',
          text:
            'Sync selected users to quickbooks. Only commercial accounts can be synced to quickbooks. ',
          buttonMethod: 'sync',
        },
      ],
      vendorOptions: [
        connectQuickBooksOption,
        {
          name: 'vendors to quickbooks',
          text: 'Sync selected vendors to quickbooks.',
          buttonMethod: 'sync',
        },
      ],
      updateOptions: [
        connectQuickBooksOption,
        {
          name: 'Update Invoice',
          text: 'Sync order to quickbooks to update an invoice',
          buttonMethod: 'sync',
        },
      ],
    };
  },
  computed: {},
  watch: {
    // productsOptions: function (newValue, oldValue) {},
    // waitingOptions: function (newValue, oldValue) {},
    // updateOptions: function (newValue, oldValue) {},
    // userOptions: function (newValue, oldValue) {},
    // fulfilledOptions: function (newValue, oldValue) {},
    // vendorOptions: function (newValue, oldValue) {},
    connectQuickBooksOption: function (newValue, oldValue) {
      console.log(newValue, oldValue);
    },
  },
  components: {
    DualButton,
  },
  mounted: function () {
    this.isQuickBooksConnected();
  },
  methods: {
    handleButtonMethods(option) {
      switch (option.buttonMethod) {
        case 'sign_in':
          this.signInQB();
          break;
        case 'sync':
          this.sync();
          break;
        case 'sync_two':
          this.syncTwo();
          break;
        default:
          break;
      }
    },
    async isQuickBooksConnected() {
      try {
        const response = await axios.get('/api/v1/quickbooks/connected');
        console.info(response);
        this.connectQuickBooksOption.name = 'Connected to QuickBooks';
        this.connectQuickBooksOption.isConnected = true;
      } catch (e) {
        this.isConnected = false;
      }
    },
    sync() {
      this.$emit('setLoading', true);
      switch (this.page) {
        case 'Users':
          this.syncUsers();
          break;
        case 'Products':
          this.syncProducts();
          break;
        case 'Vendors':
          this.syncVendors();
          break;
        case 'Awaiting Products':
          this.createPurchaseOrder();
          break;
        case 'Product Editor':
          this.syncProducts();
          break;
        case 'Fulfilled':
        case 'Update Invoice':
          this.createInvoice();
      }
      this.$emit('setLoading', false);
    },

    syncTwo() {
      this.$emit('setLoading', true);
      switch (this.page) {
        case 'Orders':
          this.force = true;
          this.syncOrders();
          break;
        case 'Products':
          this.syncFrom();
          break;
      }
      this.$emit('setLoading', false);
    },

    async signInQB() {
      try {
        const response = await axios.get('/api/v1/quickbooks/progress');
        if (
          response.data.syncer &&
          !confirm(
            `${response.data.syncer} is currently syncing to quickbooks, are you sure you would like to continue?`
          )
        ) {
          return;
        }

        // const self = this;
        const authUri = await axios.get('/api/v1/quickbooks');
        // Launch Popup using the JS window Object
        var parameters = 'location=1,width=800,height=650';
        parameters += ',left=' + (screen.width - 800) / 2 + ',top=' + (screen.height - 650) / 2;
        var win = window.open(authUri.data, 'connectPopup', parameters);
        var pollOAuth = window.setInterval(function () {
          try {
            if (win.document.URL.indexOf('code') != -1) {
              window.clearInterval(pollOAuth);
              win.close();
              window.location.reload();
            }
          } catch (e) {
            console.log(e);
          }
        }, 100);
        this.finished = !this.finished;
      } catch (error) {
        console.log(error);
        return false;
      }
    },

    async syncProducts() {
      this.$emit('setLoading', true);
      try {
        const failed = new Array();

        if (this.items.length == 0) {
          if (!confirm('Are you sure you would like to sync TO QuickBooks.')) {
            return;
          }
          await axios.patch('/api/v1/quickbooks/items');
        } else {
          //this is to go through and update the cost in quickbooks.
          if (
            !confirm(
              'Syncing with products selected will overwrite the cost of the selected variants inside quickbooks. Do you wish to proceed?'
            )
          ) {
            return;
          }
          try {
            const data = this.items.map((el) => el._id);
            await axios.post('/api/v1/quickbooks/items', data);
          } catch (error) {
            failed.push(1);
            console.log(error);
          }
        }
        if (failed.length > 0) {
          throw 'error';
        } else {
          this.$store.commit('setError', {
            message: `Successfully Saved/Updated most variants, others will be marked with a hazard sign.`,
            color: 'green',
            duration: 0,
          });
        }
      } catch (error) {
        console.log(error);
        this.$store.commit('setError', {
          message: `Failed to Save/Update at least one variant, others will be marked with a hazard sign.`,
          color: 'red',
        });
      }
      this.$emit('setLoading', false);
    },

    async syncFrom() {
      try {
        if (!confirm('Are you sure you would like to sync FROM QuickBooks.')) {
          return;
        }
        await axios.get('/api/v1/quickbooks/items');
        this.$store.commit('setError', {
          message: `The server has received this request and will take about 20 minutes to complete. Andrew will receive an email when the sync is complete. Please don't sync again for today.`,
          color: 'green',
          duration: 0,
        });
      } catch (error) {
        console.log(error);
      }
    },

    async syncUsers() {
      let success = 0;
      let failed = 0;
      for (let i = 0; i < this.items.length; i++) {
        if (this.items[i].company) {
          try {
            await axios.post('/api/v1/quickbooks/customer', this.items[i].company);
            success += 1;
          } catch (error) {
            failed += 1;
            console.log(error);
          }
        }
      }

      if (failed > 0) {
        this.$store.commit('setError', {
          message: `Failed to Sync/Update ${failed} Users.`,
          color: 'red',
          duration: 0,
        });
      } else {
        this.$store.commit('setError', {
          message: `Successfully Synced/Updated ${success} Users.`,
          color: 'green',
          duration: 0,
        });
      }
    },

    async syncVendors() {
      let success = 0;
      let failed = 0;
      for (let i = 0; i < this.items.length; i++) {
        try {
          await axios.post('/api/v1/quickbooks/vendor', this.items[i]);
          success += 1;
        } catch (error) {
          failed += 1;
          console.log(error);
        }
      }

      if (failed > 0) {
        this.$store.commit('setError', {
          message: `Failed to Sync/Update ${failed} Vendors.`,
          color: 'red',
          duration: 0,
        });
      } else {
        this.$store.commit('setError', {
          message: `Successfully Synced/Updated ${success} Vendors.`,
          color: 'green',
          duration: 0,
        });
      }
    },
    async createInvoice() {
      try {
        await axios.post('/api/v1/quickbooks/invoiceNew', this.payload);
        this.$store.commit('setError', {
          message: `Created invoice.`,
          color: 'green',
        });
        this.$emit('refresh');
      } catch (error) {
        this.$store.commit('setError', {
          message: `Failed to create Invoice.`,
          color: 'red',
          duration: 0,
        });
      }
    },
    async createPurchaseOrder() {
      try {
        await axios.post('/api/v1/quickbooks/purchaseOrder', this.payload);
        this.$store.commit('setError', {
          message: `Created purchase order.`,
          color: 'green',
        });
        this.$emit('refresh');
      } catch (error) {
        this.$store.commit('setError', {
          message: `Failed to create purchase order.`,
          color: 'red',
          duration: 0,
        });
      }
    },
  },
};
</script>
<style scoped></style>
